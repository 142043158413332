const INITIAL_STATE = {};

const AppReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default AppReducer;
